import * as React from 'react';
import { useMutation } from 'redux-query-react';
import toCartRequest, { IProductRequest } from 'data/requests/product/toCartRequest';
import quoteIdRequest from 'data/requests/product/quoteIdRequest';
import formProductRequest from 'data/requests/product/data/formProductRequest';
import { useCallback, useEffect, useState } from 'react';
import Toaster from 'ekaubamaja-ui/lib/Components/Toaster/index';
import { ResponseStatus } from '../../../enums/ResponseStatus';
import Icon from 'ekaubamaja-ui/lib/Components/Icon';
import { empty } from '../../../helpers/empty';
import { CartAmountSizes } from '../../../enums/CartAmountSizes';
import AmountSize from 'components/Common/AmountSize';
import { formatPriceLocale } from '../../../helpers/priceUtils';
import CartButton from 'components/Catalog/Product/CartButton';
import { IAmountSizeLabels } from 'components/Cart/Buttons/Alter';
import { useSelector } from 'react-redux';
import { IStoreState } from '../../../helpers/rootReducer';

export interface IProductActionsProduct {
    id: number;
    availqty: number;
    sku: string;
    name: string;
    image: string;
    isSaleable: boolean;
    isListDiscount?: number;
    price: number;
    specialPrice?: number;
    discountPercent?: number;
    caseQty: number;
    palletQty: number;
}

interface IProps {
    product: IProductActionsProduct;
    labels: IProductActionsLabels;
    isDetail?: boolean;
}

export interface IProductActionsLabels {
    addToCart: string;
    addedToCart: string;
    sum: string;
    amountSize: IAmountSizeLabels;
    pawnLabel: string;
    labelNext: string;
    labelPrev: string;
    includingDiscount: string;
    productQtyNotAvailable: string;
    productNotAvailable: string;
}

const ProductActions = (props: IProps) => {
    const { product, labels } = props;
    const [{}, quoteRequest] = useMutation(() => quoteIdRequest());
    const [{ isPending }, addToCartRequest] = useMutation((data: IProductRequest, quoteIdMask: string) =>
        toCartRequest(data, quoteIdMask),
    );
    const [inputAmount, setInputAmount] = useState<number>(1);
    const [disabled, setDisabled] = useState(false);
    const [currentAmountOver, setCurrentAmountOver] = useState(false);
    const [inputSizeAmount, setInputSizeAmount] = useState<number>(1);
    const [selectedAmountSize, setSelectedAmountSize] = useState<string>(CartAmountSizes.PIECE);
    const { isListView } = useSelector((state: IStoreState) => state.gridViewReducer);

    const addToCart = useCallback(async () => {
        setDisabled(true);
        if (isPending || disabled) {
            return;
        }
        const quoteIdResponse = await quoteRequest();
        if (quoteIdResponse.status !== ResponseStatus.ok) {
            // Todo maybe a message?
            window.location.reload();
        }
        const { quoteId } = quoteIdResponse.body;

        const productRequestData: IProductRequest = formProductRequest(quoteId, product.sku, inputSizeAmount);
        const response = await addToCartRequest(productRequestData, quoteId);
        setDisabled(false);
        if (response.status === 200) {
            product.availqty = product.availqty - (response?.body?.qty ?? 0);
            if (product.availqty < 1) {
                product.availqty = 0;
                setInputAmount(0);
                setCurrentAmountOver(true);
            }

            window.dispatchEvent(
                new CustomEvent('cart-altered', {
                    detail: {
                        productId: product.id,
                        name: product.name,
                        image: product.image,
                        sku: product.sku,
                    },
                }),
            );
            Toaster.addToast({
                intent: 'success',
                text: labels.addedToCart.replace('%1', product.name),
                asHtml: true,
            });
        } else {
            if (response?.body?.message) {
                Toaster.addToast({
                    intent: 'danger',
                    text: response?.body?.message,
                    asHtml: true,
                });
            }
        }
    }, [addToCartRequest, quoteRequest, inputAmount]);

    const isBox = () => selectedAmountSize === CartAmountSizes.BOX && product.caseQty && !empty(product.caseQty);
    const isPallet = () =>
        selectedAmountSize === CartAmountSizes.BASE && product.palletQty && !empty(product.palletQty);

    const validateAmountOver = (amount: number) => {
        if (selectedAmountSize === CartAmountSizes.PIECE) {
            if (amount > product.availqty) {
                setCurrentAmountOver(true);
                amount = product.availqty;
            }
        } else if (isBox()) {
            if (amount * product.caseQty > product.availqty) {
                setCurrentAmountOver(true);
                amount = Math.floor(product.availqty / product.caseQty);
            }
        } else if (isPallet()) {
            if (amount * product.palletQty > product.availqty) {
                setCurrentAmountOver(true);
                amount = Math.floor(product.availqty / product.palletQty);
            }
        }
        if (amount < 0) {
            setCurrentAmountOver(true);
            return 0;
        }
        return amount;
    };
    const setProductAmount = (amount: number) => {
        setCurrentAmountOver(false);
        if (amount <= 0) {
            return;
        }
        amount = validateAmountOver(amount);
        setInputAmount(amount);
    };

    useEffect(() => {
        if (!empty(inputAmount)) {
            if (selectedAmountSize === CartAmountSizes.PIECE) {
                setInputSizeAmount(inputAmount);
            } else if (isBox()) {
                setInputSizeAmount(inputAmount * product.caseQty);
            } else if (isPallet()) {
                setInputSizeAmount(inputAmount * product.palletQty);
            }
            const amount = validateAmountOver(inputAmount);
            if (amount !== inputAmount) {
                setInputAmount(amount);
            }
        }
    }, [inputAmount, selectedAmountSize]);

    return (
        <React.Fragment>
            {product && product.isSaleable && product.price > 0 && (
                <div className="box-product__cart">
                    {!props.isDetail && !isListView && product.availqty < 1 && (
                        <div className={'cart-control__msg'}>{labels.productNotAvailable}</div>
                    )}
                    {product.availqty > 0 && (
                        <button
                            onClick={() => void addToCart()}
                            className="cart-control__button"
                        >
                            <span className="wrapper">
                                <CartButton addToCartLabel={labels.addToCart} />
                            </span>
                        </button>
                    )}
                    <div className="cart-control">
                        <div className="cart-control__row">
                            <div className="cart-control__amount">
                                <button
                                    disabled={isPending || product.availqty < 1}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setProductAmount(inputAmount - 1);
                                    }}
                                >
                                    <Icon
                                        width={10}
                                        height={10}
                                        kind="minus"
                                    />
                                </button>
                                <input
                                    onChange={(e) => {
                                        let amount = parseInt(e.target.value);
                                        if (empty(amount) || amount <= 0) {
                                            amount = 1;
                                        }

                                        if (amount >= product.availqty) {
                                            amount = product.availqty;
                                            setCurrentAmountOver(true);
                                        }

                                        setInputAmount(amount);
                                    }}
                                    disabled={isPending || product.availqty === 0}
                                    type="number"
                                    value={inputAmount}
                                />
                                <AmountSize
                                    product={{
                                        caseQty: product.caseQty,
                                        palletQty: product.palletQty,
                                    }}
                                    labels={labels.amountSize}
                                    selectedAmountSize={selectedAmountSize}
                                    setSelectedAmountSize={setSelectedAmountSize}
                                />
                                <button
                                    disabled={isPending || product.availqty === 0}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setProductAmount(inputAmount + 1);
                                    }}
                                >
                                    <Icon
                                        width={10}
                                        height={10}
                                        kind="plus"
                                    />
                                </button>
                            </div>
                            <div className="cart-control__buttons">
                                <button
                                    onClick={() => void addToCart()}
                                    className="primary"
                                    disabled={disabled || isPending || product.availqty === 0}
                                >
                                    <CartButton
                                        addToCartLabel={
                                            product.availqty === 0 ? labels.productNotAvailable : labels.addToCart
                                        }
                                    />
                                </button>
                            </div>
                        </div>
                        {currentAmountOver && (
                            <div className="cart-control__row">
                                <p className="cart-control__error">{labels.productQtyNotAvailable}</p>
                            </div>
                        )}

                        <div className="cart-control__row">
                            <p className="cart-control__extra">
                                <span className="cart-control__extra__block">
                                    {labels.sum}:{' '}
                                    <b>
                                        {`${formatPriceLocale(
                                            (product.specialPrice ?? product.price) *
                                                parseFloat(inputSizeAmount.toString()),
                                        )}`}
                                    </b>
                                </span>
                                {!!product.isListDiscount && (
                                    <span className="cart-control__extra__block">
                                        {labels.includingDiscount} -{product.discountPercent}%
                                    </span>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default ProductActions;
